import Head from 'next/head';
import React, { useEffect } from 'react';
import { handleLogEvent } from "config/firebaseAnalytics";
import { useRouter } from 'next/router';

const handleParams = (title, pathname, query, asPath) => {
  let result = {
    page_title: title,
    page_query: query,
    page_path: pathname,
    page_aspath: asPath,
  }

  return result
}

export default function Layout({ title, children }) {
  const { pathname, query, asPath } = useRouter();
  
  useEffect(() => {
    const eventParams = handleParams(title, pathname, query, asPath)

    handleLogEvent("one21_page_view", eventParams)
  }, [title])

  return (
    <div>
      <Head>
        <title>{title || 'One21'}</title>
        <link
          rel='icon'
          type='image/x-icon'
          href='../../static/images/favicon.ico'
        />
      </Head>
      {children}
    </div>
  );
}
