import { initializeApp, getApps} from "firebase/app";
import { getAnalytics, isSupported, logEvent } from "firebase/analytics";

// Initialize Firebase
let app; let analytics;

if (typeof window != undefined && getApps().length === 0) {
  const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_DATA_BASE_URL,
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
  };

  app = initializeApp(firebaseConfig);
  analytics = isSupported().then(yes => yes ? getAnalytics(app) : null);
}

export const handleLogEvent = (eventName, eventParams) => {
  if(getApps().length === 0){
    !!analytics && logEvent(analytics, eventName, eventParams)
  }
}

export default analytics
